const SkillsObjectArray = () =>{

    return [
      { title: "JAVA", header: "Java", body:"Java has been one of my primary development tools for the last 7 years. I have created frameworks, built microserves, and troubleshot as part of production support", group:"Backend"},
      { title: "HTML", header: "HTML", body:"PolicyCenter Framework is built on HTML, CSS, and Angular. I have Seven years experience in HTML", group:"Frontend"},
      { title: "REACT", header: "REACT", body:"Built and maintained green project user interfaces for PolicyCenter portals. This webiste is built in REACT", group:"Frontend"},
      { title: "Responsive Web Design", body:"Used REACT, ANGULAR, and Javascript to create dynamic webpages for Policycenter portals and personal web development",header: "Responsive Web Design", group:"Frontend" },
      { title: "GOSU", header: "GOSU" , body:"GOSU is the primary language for PolicyCenter. It is a language built to compile with JAVA's JVM framework. I describe it as JAVA with some convenience methods." ,group:"Backend"},
      { title: "Mentoring", header: "Mentoring", body:"Lead Developer for 5 years. Performed code reviews, coahed development, set up environments" },
      { title: "Design Patterns", header: "Design Patterns", body:"Built microservices and full green projects using several types of design patterns researched for effectiveness" },
      { title: "Unit Testing", header: "Unit Testing", body:"Built a full GUnit suite in PolicyCenter and utilized JUnits for microservice unit testing"},
      { title: "Performance Analysis", header: "Built logging systems and proxy calls to track processing times before implementing appdynamics." },
      { title: "Python", header: "Built Microserves and the primary language I use in my personal problem solving development challenges.", group:"Backend" },
      { title: "SVN", header: "SVN", body:"Primary resource managment system at Farm Bureau. Manually managed before implementation of GIT and Jenkins" },
      { title: "JavaScript", header: "JavaScript", body:"Built micorserves and used as part of PolicyCenter Portal desingn", group:"Frontend" },
      { title: "Code Review", header: "Code Review", body:"Conducted Code review in my role as lead software developer. Conducted Developer touchpoint meetings where code was reviewed in a group setting"},
      { title: "SOAP", header: "SOAP" , group:"Backend", body:"SOAP is used throughout PolicyCenter integrations and between microservices calls"},
      { title: "Microservice Integration", header: "Microservice Integration", body:"Used several langueages including JAVA,python, and node.js to build microservices. Integrated to it primarily throgh OAP and JSON" },
      { title: "Agile Development", header: "Agile Development", body:"Agile and safe training certifications. Experience developing in and running scrums" },
      { title: "OOP Design", header: "OOP Design", body:"Built microservices and full green projects using several types of design patterns researched for effectiveness", group:"Backend" },
      { title: "Angular", header: "Angular", body:"PolicyCenter uses Angular as its primary language for its user interface.", group:"Frontend" },
      { title: "Branch management", header: "Primary resource managment system at Farm Bureau. Manually managed before implementation of GIT and Jenkins" },
      { title: "SQL", header: "SQL" , body:"Used extensively i nPolicyCenter for project deign, troubleshooting, and informational queries" ,group:"Backend"},
      { title: "AppDynamics", header: "AppDynamics", body:"Used to troubleshoot performance problem areas", group:"Frontend" },
      { title: "Accounting Transaction Development", header: "Subject matter expert in PolicyCenter for cost and transaction building in OOS scenarios", body:"This is s Test Of" },
      { title: "XML", header: "XML", body:"Used extensivle in PolicyCenter integrations including an integration point to every downstream sytstem conducted on a single Accord XML" , group:"Backend"},
      { title: "Selenium", header: "Selenium", body:"Developed automated test suite for recursive testing and unit testing for defects and stories", group:"Frontend" },
      { title: "CSS3", header: "CSS3", body:"Used primarily in my personal website design",group:"Frontend" },
      { title: "Git", header: "Git", body:"Primary resource managment system at Kemper and personal projects"},
      { title: "IntelliJ IDEA", header: "IntelliJ IDEA", body:"PolicyCenter uses IntelliJ as its IDE. Additionally I use it personally for my JAVA projects" , group:"Backend"},
      { title: "Operations/Project Management", header: "Operations/Project Management", body:"Used Rally and Safe agile practices to manage projects and defects as a scrum master" },
      { title: "Visual Studio Code", header: "Visual Studio Code", body:"My primary IDEA for Angular, react, and javascript development", group:"Frontend" },
      { title: "PolicyCenter", header: "PolicyCenter", body:"PolicyCenter was the primary framework I worked on. INcuded GOUS,JAVA,XML and array of other skills", group:"Frontend"},
      
  
    ];
  };

  export default SkillsObjectArray;
  