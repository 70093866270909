const WorkExperienceItemFilter = (props) => {


 let optionSet = new Set();   
for (let aWorkListItem of props.unfilteredOptions.workList){
    for(let anItem of aWorkListItem.workGroup){
        optionSet.add(anItem)
    };
};

  const groupOptions = [...optionSet];


  return (
    <div>
      <div className="expenses-filter__control">
        <label className="expenses-filter__label">Filter by Skill Group</label>
        <select
          className="expenses-filter__select"
          value={props.defaultGroup}
          onChange={props.dropDownChange}
        >
          {
          groupOptions.map((anOption) => (
            <option key={anOption} value={anOption}>{anOption}</option>
          ))
          }
          
          
        </select>
      </div>
    </div>
  );
};

export default WorkExperienceItemFilter;
