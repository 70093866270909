const QualificationsFilter = (props) => {
  return (
    <div>
      <div className="expenses-filter__control">
        <label className='expenses-filter__label'>Filter by Skill Group</label>
        <select className='expenses-filter__select' value={props.defaultGroup} onChange={props.dropDownChange}>
          <option value="All">All</option>
          <option value="Frontend">Frontend</option>
          <option value="Backend">Backend</option>
          <option value="Fullstack">Fullstack</option>
        </select>
      </div>
    </div>
  );
};

export default QualificationsFilter;
