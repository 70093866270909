import "./ResumeHead.css";

const ResumeHead = () => {
  return (
    <div className="header">
      <h1>David Vaun Davis</h1>
      <p>Online interactive resume</p>
    </div>
  );
};
export default ResumeHead;
