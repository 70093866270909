import "bootstrap/dist/css/bootstrap.min.css";
import "./ResumePage.css";
import SkillsObjectArray from "./qualifications/SkillsObjectArray";
import Qualifications from "./qualifications/Qualifications";
import { useState } from "react";
import ExperienceItems from "./workexperience/ExperienceItems";
import WorkExperience from "./workexperience/WorkExperience";

const WorkGroup = () => {

  const [filterOption, setFilterOption] = useState('JAVA');

  const dropDownChangeHadler = (event) => {
    setFilterOption(event.target.value);
  };

  const filteredOptions = ExperienceItems().workList.filter((anItem) =>
    anItem.workGroup.includes(filterOption)
  );

  return (
    <div className="resumepage">
      <Qualifications
        skills={SkillsObjectArray()}
        dropDownChange={dropDownChangeHadler}
      />
      <WorkExperience
        filteredOptions={filteredOptions}
        dropDownChange={dropDownChangeHadler}
      />
    </div>
  );
};

export default WorkGroup;
