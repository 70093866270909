import Container from "react-bootstrap/Container";
import logo from "../../../images/ISU-horizontal.png";
import "./Education.css";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

const Education = () => {
  return (
    <Container className="eduContainer">
      <h2 className="eduCenter">Education</h2>
      <Container className="eduContainer">
        <Row>
          <Col className="eduContainer__p" md="4" sm="12">
              Idaho State University 
              Bachelor of Science in Computer Information Systems
              
          </Col>
          <Col className="eduContainer__p" md="4" sm="12">Graduated June 2013</Col>
          <Col md="4" sm="12">
            <a href="https://www.isu.edu/">
              <img src={logo} className="eduContainer__item" alt="ISU" />
            </a>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};
export default Education;
