import { Row } from "react-bootstrap";
import { Col } from "react-bootstrap";
import Container from "react-bootstrap/Container";

const WorkExperienceDetails =(props) =>{
    return (
        <Container className="expDetailsContainer">
        <Row>
          <Col md="4" sm="12">
            <div className="expitem">{props.experience.position}</div>
          </Col>
          <Col md="4" sm="12">
            <div className="expitem">{props.experience.company}</div>
          </Col>
          <Col md="4" sm="12">
            <div className="expitem">{props.experience.daterange}</div>
          </Col>
        </Row>
      </Container>
    );
};

export default WorkExperienceDetails;