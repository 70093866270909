import SkillPopover from "./SkillPopover";
import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";



const QualificationsListItem = (props) =>{


    return (
        <OverlayTrigger
        className="skillz__Col2"
        trigger={['hover', 'focus']}
        placement="auto"
        overlay={SkillPopover(props)}      >
        <Button key={props.skill.title} className="skillz__Col" variant="success">
          {props.skill.title}
        </Button>
      </OverlayTrigger>
    );
};

export default QualificationsListItem;