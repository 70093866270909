
import "./Qualifications.css";
import { useState } from "react";
import QualificationsFilter from "./QualificationsFilter";
import QualificationsListItem from "./QualificationsListItem";





const QualificationsList = (props) => {
  const [skillFilter, setSkillFilter] = useState("All");


  const filteredSklls = () => {
    if (skillFilter === "All") {
      return props.skills;
    } else if (skillFilter === "Fullstack") {
      return props.skills.filter(
        (aSkill) => aSkill.group === "Frontend" || aSkill.group === "Backend"
      );
    }
    return props.skills.filter((aSkill) => aSkill.group === skillFilter);
  };

  const dropdownChangeHandler = (event) => {
    setSkillFilter(event.target.value);
  };

  return (
    <div>
      <QualificationsFilter dropDownChange={dropdownChangeHandler}/>
      {filteredSklls().map((aSkill) => (
        <QualificationsListItem key={aSkill.title} skill={aSkill} dropDownChange={props.dropDownChange}/>
      ))}
    </div>
  );
};

export default QualificationsList;
