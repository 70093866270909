const ExperienceItems = () => {
  return {
    workList: [
        {workGroup : ["JAVA","GOSU","Unit Testing","Performance Analysis","Agile Development","Selenium"],worktext :"Developed initial framework for automated JUnit testing."},
        {workGroup : ["JAVA", "GOSU","PolicyCenter","SQL","XML","Microservice Integration"],worktext :"Integrated the legacy mainframe system with Message Broker to allow for the transition renewal of legacy policies."},
        {workGroup : ["JAVA","XML","GOSU"],worktext :"Developed a document generation integration using custom inference engine and xml."},
        {workGroup : ["JAVA","GOSU","XML","PolicyCenter"],worktext :"Integrated PolicyCenter with Oracle Insbridge to calculate premium."},
        {workGroup : ["PolicyCenter"],worktext :"Developed product model for the initial product launch and subsequent line launches."},
        {workGroup : ["PolicyCenter","JAVA","JavaScript","Mentoring"],worktext :"Worked on teams of 6 to divide the entire launch into manageable independent stories that could be worked on in an Agile development method."},
        {workGroup : ["JAVA","GOSU","Unit Testing","Accounting Transaction Development","PolicyCenter"],worktext :"Company wide Subject matter expert on transaction generation integrations into billing system and the general ledger."},
        {workGroup : ["JAVA","GOSU","Unit Testing","PolicyCenter"],worktext :"Developed integration between policy system and billing system to allow multiple payers."},
        {workGroup : ["JAVA","GOSU","Unit Testing","PolicyCenter","XML","SOAP","Accounting Transaction Development"],worktext :"Developed membership program integration to legacy system and billing system."},
        {workGroup : ["JAVA","GOSU","Unit Testing","BillingCenter","PolicyCenter","XML","SOAP"],worktext :"Integrated with the legacy system to create custom general ledger and compare policy balances across systems.."},
        {workGroup : ["SQL"],worktext :"Created sql queries to correct database inconsistencies."},
        {workGroup : ["JAVA","GOSU","BillingCenter","PolicyCenter","Performance Analysis"],worktext :"Refactored charge processor to build charges based on package policies including overriding invoice streams and tag along policies."},
        {workGroup : ["SVN","Jenkins","IntelliJ IDEA","Performance Analysis"],worktext :"Configured automated build and deploy for production and testing environments."},
        {workGroup : ["BillingCenter","PolicyCenter","GOSU"],worktext :"Used GuideWire scripting api to make corrective data changes."},
        {workGroup : ["BillingCenter","PolicyCenter","GOSU","SQL"],worktext :"Used SQL Queries to gather information and make database changes."},
        {workGroup : ["Microservice Integration","Microservice Integration","SOAP","XML"],worktext :"Updated and configured Message broker integrations."},
        {workGroup : ["BillingCenter","PolicyCenter","SOAP","Microservice Integration"],worktext :"Managed PolicyCenter to BillingCenter APIs."},
        {workGroup : ["BillingCenter","PolicyCenter","SOAP","Microservice Integration"],worktext :"Monitored and managed SOAP messages."},
        {workGroup : ["IntelliJ IDEA","Angular","Google Analytics", "SOAP","CSS3","Responsive Web Design"],worktext :"Resolved user interface errors using IntelliJ IDEA to update Angular based HTML."},
        {workGroup : ["Selenium","JAVA","GOSU"],worktext :"Maintained Java based Selenium testing suite for automated user interface driven testing."},
        {workGroup : ["JAVA","GOSU","Unit Testing","Performance Analysis"],worktext :"Maintained JUnit testing sweet suite for automated unit testing."},
        {workGroup : ["Frontend","XML","Mentoring","Performance Analysis"],worktext :"Lead Developer/Architect for a team that created integrations from our current system to a legacy database warehouse"}, 
        {workGroup : ["BillingCenter","PolicyCenter","SOAP","Microservice Integration"],worktext :"document generating api, and financial balancing service."},
        {workGroup : ["BillingCenter","PolicyCenter","SOAP","Microservice Integration","Operations/Project Management",],worktext :"Lead developer for an 8-Person Policy Center Team. Consisting of developing in the GOSU programming language and working with the company’s policy event routing system."},
        {workGroup : ["BillingCenter","PolicyCenter","SOAP",],worktext :"As a Lead developer, created and maintained development processes for my team to increase code quality and minimize unexpected defects."},
        {workGroup : ["Mentoring","Branch management", "Operations/Project Management", "Code Review"],worktext :"Mentored 3 developers by providing guidance in technical , leadership, team building, and interpersonal skills"},
        {workGroup : ["JAVA","GOSU","Unit Testing","Selenium","AppDynamics"],worktext :"Created testing scenarios and templates for assigned production issues. Tools such as JUnits, AppDynamics, Selenium were used in the triage and troubleshooting process."},
        {workGroup : ["JAVA","GOSU","XML","SQL","CSS3","HTML"],worktext :"Developed on Production Support issues with Java, GOSU, XML, SQL, CSS, and HTML. Consists of reviewing a problem, analyzing the symptoms, and identifying the issues down to root cause to develop a solution."},
        {workGroup : ["JAVA","GOSU","Unit Testing","Selenium","AppDynamics","IntelliJ IDEA","Angular","XML","SQL"],worktext :"Member of a 2 person team that developed unit testing capabilities in several applications where only end-to-end testing was previously available."},
        {workGroup : ["Code Review","Unit Testing","JAVA","GOSU","SVN","GIT"],worktext :"Conducted pre-deploy code reviews with the team to ensure code quality."},
        {workGroup : ["JAVA","GOSU","XML","SOAP"],worktext :"Developed a microservice that accepts XMLs and performs financial balancing"},
        {workGroup : ["Accounting Transaction Development","PolicyCenter","BillingCenter"],worktext :"Company wide subject matter expert on out of sequence transactions and financial balancing across both legacy and current policy/billing systems."},
        {workGroup : ["PolicyCenter"],worktext :"Provides final approval on all product model changes made in code that impact an enterprise level architecture."},
        {workGroup : ["AppDynamics","SQL","PolicyCenter","BillingCenter"],worktext :"Conducted Performance Analysis using AppDynamics to locate and correct troublespots."},

    ],
  };
};

export default ExperienceItems;
