import Popover from 'react-bootstrap/Popover'

const SkillPopover = (props) => {
  return (
  <Popover id="popover-basic">
    <Popover.Header as="h3">{props.skill.header}</Popover.Header>
    <Popover.Body>{props.skill.body}</Popover.Body>
  </Popover>
  );
};
export default SkillPopover;
