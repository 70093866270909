import React from 'react';
import ResumePage from './components/resume/ResumePage'

import './App.css';


const App = () => (
<div>
  <ResumePage />

</div>
);

export default App;
