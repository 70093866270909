import QualificationsList from "./QualificationsList";

const Qualifications = (props) => {
  return (
    <div>
      <h2 className="eduCenter">SUMMARY OF QUALIFICATIONS</h2>
      <QualificationsList skills={props.skills} dropDownChange={props.dropDownChange} />;
    </div>
  );
};

export default Qualifications;
