import "bootstrap/dist/css/bootstrap.min.css";
import ContactInfo from "./contactinfo/ContactInfo";
import "./ResumePage.css";
import ResumeHead from "./ResumeHead";
import Education from "./education/Education";
import WorkGroup from "./WorkGroup";


const ResumePage = () => {

  return (
    <div className="resumepage">
      <ResumeHead />
      <ContactInfo />
      <WorkGroup />
      <Education />
    </div>
  );
};

export default ResumePage;
