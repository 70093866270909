import Container from "react-bootstrap/Container";
import "./WorkExperience.css";
import WorkExperienceItem from "./WorkExperienceItem";
import ExperienceItems from "./ExperienceItems";

const WorkExperience = (props) => {

  return (
    <Container className="expContainer">
      <h2 className="expCenter">Work Experience</h2>
      <Container className="expContainer">
        <WorkExperienceItem unfilteredOptions={ExperienceItems()} filteredOptions={props.filteredOptions} dropDownChange={props.dropDownChange} />
      </Container>
    </Container>
  );
};
export default WorkExperience;
