const JobList = () => {
  return [
    {
      position: "Lead Software Developer IV",
      company: "Kemper Insurance",
      daterange: "October 2019 – Present",
    },
    {
      position: "Lead Software Developer",
      company: "Idaho Farm Bureau Insurance ",
      daterange: "October 2014 – October 2019",
    },
  ];
};

export default JobList;
