import { ListGroup } from "react-bootstrap";
import WorkExperienceDetails from "./WorkExperienceDetails";
import WorkExperienceItemFilter from "./WorkExperienceItemFilter";
import "./WorkExperience.css";
import JobList from "./JobList";

const WorkExperienceItem = (props) => {

  return (
    <div>
      {JobList().map((anexperience) => (
        <WorkExperienceDetails key={anexperience.position} experience={anexperience} />
      ))}
      ;
      <WorkExperienceItemFilter
        dropDownChange={props.dropDownChange}
        unfilteredOptions={props.unfilteredOptions}
      />
      <ListGroup variant="flush">
        {props.filteredOptions.map((workItem) => (
          <ListGroup.Item key={workItem.worktext} >{workItem.worktext}</ListGroup.Item>
        ))}
      </ListGroup>
    </div>
  );
};

export default WorkExperienceItem;
