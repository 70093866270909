import "./ContactInfo.css";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";

const ContactInfo = () => {
  const email = "davidvaundavis@Gmail.com";
  const linkedIn = "linkedin.com/in/davidvaundavis";

  return (
    <Container className="conDetailsContainer">
      <Row>
        <Col md="4" sm="12">
          <div className="conitem">
            <Button variant="primary" href="mailto:davidvaundavis@Gmail.com">
              {email}
            </Button>
          </div>
        </Col>
        <Col md="4" sm="12">
          <div className="conitem"></div>
        </Col>
        <Col md="4" sm="12">
          <div className="conitem">
            <Button variant="primary" href={linkedIn}>
              {linkedIn}
            </Button>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default ContactInfo;
